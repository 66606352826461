import { ErrorListener } from './Listeners';
import { DataModel } from '../DataModel';
import {
  AvailableComponents,
  AvailableHoleHeights,
  Component,
} from './Component';
import {
  AvailableBSideDoorSectorMaterials,
  AvailableDoorFrames,
  AvailableDoorSectorMaterials,
  AvailableFrameMaterials,
} from './Material';
import { Order } from '../Order/Order';
import { ImageCapture, ImageCaptureStyle, OrderItems } from '../Order';
import { EngineError } from '.';

export type UpdateDataModelResponse = {
  success: boolean;
  errors: EngineError[];
  modelChanged: boolean;
};

export enum NonRecessedDoorsRenderPosition {
  SIDE = 'side',
  FRONT = 'front',
}

export type DoorSectorSelectionFunction = (
  door: number,
  sector: number,
) => void;
export declare class EngineInterface {
  constructor(params: {
    element: HTMLElement;
    dataSet: unknown;
    textureBaseUrl: string;
    fontBaseUrl: string;
    onDoorSector?: DoorSectorSelectionFunction;
    controlsEnabled?: boolean;
    layers?: number[];
    nonRecessedDoorsRenderPosition?: NonRecessedDoorsRenderPosition;
  });
  get version(): string;
  setErrorListener: (listener: ErrorListener) => void;
  setDataModel: (dataModel: DataModel) => Promise<UpdateDataModelResponse>;
  setBackgroundColor: (color: number) => void;
  setGridVisibility: (visible: boolean) => void;
  getAllComponents: () => Component[];
  getAvailableComponents: () => AvailableComponents;
  getAvailableFrameMaterials: () => AvailableFrameMaterials;
  getAvailableHoleHeights: () => AvailableHoleHeights;
  getAvailableDoorFrames: () => AvailableDoorFrames;
  getAvailableDoorSectorMaterials: () => AvailableDoorSectorMaterials;
  getAvailableBSideDoorSectorMaterials: () => AvailableBSideDoorSectorMaterials;
  getOrder: (baseOrder?: Partial<OrderItems>, force?: boolean) => Order;
  setControlsEnabled: (enabled: boolean) => void;
  captureImages: (
    captureStyle: ImageCaptureStyle,
    maxSize?: number,
  ) => Promise<ImageCapture[]>;
  clearCaptureState: () => void;
  dispose: () => void;
  refresh: () => UpdateDataModelResponse;
}
