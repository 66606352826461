import React, { ReactNode, ReactElement } from 'react';
import {
  createStyles,
  FormLabel,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Add, HighlightOff, Lock, LockOpenOutlined } from '@material-ui/icons';
import { ErrorDisplay } from '../../components/errorDisplay/ErrorDisplay';

const useStyles = makeStyles(() =>
  createStyles({
    center: { display: 'flex', flexBasis: '100%', justifyContent: 'center' },
    leftSide: { flexBasis: '40%' },
    rightSide: { flexBasis: '60%' },
    label: {
      color: 'rgba(48,48,48,1)',
      marginTop: '1.4rem',
      fontSize: '0.8rem',
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    actionButtons: {
      fled: 1,
      justifyContent: 'flex-end',
    },
    actionButton: {
      padding: 0,
      '&:last-child': {
        padding: '0 10px 0 0',
      },
    },
    lightLabel: {
      fontWeight: 300,
    },
  }),
);
export const SideBarSectorItem = (props: {
  children?: ReactNode;
  label?: string;
  lightLabel?: boolean;
  disabled?: boolean;
  onRemove?: () => void;
  onAdd?: () => void;
  onLock?: () => void;
  locked?: boolean;
  name?: string;
  error?: string;
  fatDivider?: boolean;
}): ReactElement => {
  const classes = useStyles();
  const hasLabel = !!props.label;

  return (
    <>
      {hasLabel && (
        <div className={classes.leftSide}>
          <FormLabel
            component="legend"
            className={
              props.lightLabel
                ? `${classes.label} ${classes.lightLabel}`
                : classes.label
            }
          >
            {props.label}
            <div className={classes.actionButtons}>
              {props.onRemove && (
                <IconButton
                  aria-label="remove"
                  onClick={() => props.onRemove && props.onRemove()}
                  size="medium"
                  color="primary"
                  disabled={props.disabled}
                  className={classes.actionButton}
                >
                  <HighlightOff />
                </IconButton>
              )}
              {props.onAdd && (
                <IconButton
                  aria-label="add more"
                  onClick={() => props.onAdd && props.onAdd()}
                  size="medium"
                  color="primary"
                  disabled={props.disabled}
                  className={classes.actionButton}
                >
                  <Add />
                </IconButton>
              )}
              {props.onLock && (
                <IconButton
                  aria-label={
                    props.locked ? 'opening locked' : 'opening unlocked'
                  }
                  onClick={() => props.onLock && props.onLock()}
                  size="medium"
                  color="primary"
                  disabled={props.disabled}
                  className={classes.actionButton}
                >
                  {props.locked ? <Lock /> : <LockOpenOutlined />}
                </IconButton>
              )}
            </div>
          </FormLabel>
        </div>
      )}
      <div className={hasLabel ? classes.rightSide : classes.center}>
        {props.children}
        {props.error && <ErrorDisplay error={props.error} />}
      </div>
    </>
  );
};
