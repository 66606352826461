import { User } from '../models/User';

export const getAdminUsers = (usersArr: User[]) => {
  return usersArr.filter((user: User) => {
    return user.role === 'admin';
  });
};

export const getNormalUsers = (usersArr: User[]) => {
  return usersArr.filter((user: User) => {
    return user.role === 'retailer' || user.role === 'factory';
  });
};

export const getActiveUsers = (users: User[]) => {
  return users.filter((user: User) => {
    return user.active === true;
  });
};

export const getInactiveUsers = (users: User[]) => {
  return users.filter((user: User) => {
    return user.active === false;
  });
};
