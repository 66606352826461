import { ReactNode, ReactPortal, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const TopBarPortal = (props: { children: ReactNode }): ReactPortal => {
  const mount = document.getElementById('topBarPortal');
  const el = document.createElement('div');

  useEffect(() => {
    if (mount) {
      mount.appendChild(el);
      return () => {
        mount.removeChild(el);
      };
    }
  }, [el, mount]);

  return createPortal(props.children, el);
};
