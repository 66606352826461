export enum TemplateType {
  ANY = 'any',
  HALLWAY = 'hallway',
  BEDROOM = 'bedroom',
  LIVINGROOM = 'livingroom',
  HOUSEKEEPING = 'housekeeping',
}

export interface TemplateData {
  doorAmounts: number[];
  type: TemplateType;
  basketOpenings: number;
  shelfOpenings: number;
  barOpenings: number;
  shoeRackOpenings: number;
  cleaningOpenings: number;
  drawerOpenings: number;
  minWidth?: number; //FI: minimi syvyys
  maxWidth?: number; //FI: maksimi syvyys
  minHeight?: number; //FI: minimi korkeus
  maxHeight?: number; //FI: maksimi korkeus
  minDepth?: number; //FI: minimi syvyys
  maxDepth?: number; //FI: maksimi syvyys
  previewImage?: string;
}
