import {
  DoorMaterialDirection,
  MaterialID,
  DoorFrameMaterial,
} from './Material';

export type DataModel = {
  version: 1.0; // future versions must be hold in separate files
  drawMeasures: boolean;
  drawEdges: boolean;
  drawShadows: boolean;
  hasFrame: boolean;
  hasDoors: boolean;
  drawDoors: boolean;
  recessedDoors: boolean; //Doors inside the frame.
  isDivider: boolean; // Are doors used as a divider.
  frame: Frame | null;
  doors: SlidingDoors | null;
};

export type Frame = {
  width: number; //FI: leveys
  height: number; //FI: korkeus
  depth: number; //FI: syvyys
  backgroundPlate: BackgroundPlate | null; //FI: taustalevy
  basePlate: boolean; //FI: pohjalevy
  coverPlate: boolean; //FI: kattolevy
  plinth: FramePlinth | null; //FI: sokkeli
  lightPlate: boolean;
  lightPlateSwitch: boolean;
  materials: FrameMaterials;
  content: FrameInnerElements[];
};

export type FrameMaterials = {
  [key: string]: MaterialID | null;
  frame: MaterialID | null;
  inner: MaterialID | null;
  shelf: MaterialID | null;
  backgroundPlate: MaterialID | null;
};

export type FramePlinth = {
  height: number; //FI: korkeus 48-200mm
  legs: boolean;
};

export enum BackgroundPlateTypes {
  INNER_3 = 'inner_3', //Can only use 3mm materials
  OUTER_3 = 'outer_3', //Can only use 3mm materials
  OUTER_16 = 'outer_16', //Can only use 16mm materials
}

export type BackgroundPlate = {
  type: BackgroundPlateTypes;
};

export type FrameInnerElements = FrameOpening | FramePartitionWall;

export enum FrameInnerElementTypes {
  'OPENING' = 'opening',
  'WALL_NORMAL' = 'wall_normal',
  'WALL_T_TYPE' = 'wall_t_type',
  'WALL_FRAME' = 'wall_frame',
}

export type FrameOpening = {
  type: FrameInnerElementTypes.OPENING;
  locked: boolean;
  width: number;
  components: FrameComponent[];
  children?: ChildOpenings;
};

export type ChildOpenings = { [key: string]: FrameComponent[] };

export type FramePartitionWall =
  | FramePartitionWallNormal
  | FramePartitionWallT
  | FramePartitionWallFrame;

export type FramePartitionWallNormal = {
  type: FrameInnerElementTypes.WALL_NORMAL;
};

export type FramePartitionWallT = {
  type: FrameInnerElementTypes.WALL_T_TYPE;
  height: number;
};

export type FramePartitionWallFrame = {
  type: FrameInnerElementTypes.WALL_FRAME;
};

//TODO: FrameComponent should be union of component types which need to be described one by one
export type FrameComponent = {
  modelID: string;
  holeHeight: number;
  params?: ComponentParams;
};

export type ComponentParams = { [key: string]: string | number };

export type SlidingDoors = {
  height?: number; //FI: kiskojen korkeus
  topWidth?: number; //FI: kiskojen leveys ylhäällä
  bottomWidth?: number; //FI: kiskojen leveys alhaalla
  frameType: string | null;
  frameMaterial: DoorFrameMaterial | null;
  handles?: boolean;
  dampers?: number;
  dividerFrame?: boolean;
  dividerFrameEnds?: number;
  railsAmount?: number;
  slidingDoors: SlidingDoor[];
};

export type SlidingDoor = {
  width: number; // Door width calculated by engine
  sectors: SlidingDoorSector[];
};

export type SlidingDoorSector = {
  locked: boolean;
  height: number; // Given by user, or calculated from percent. Engine fixes unlocked heights automatically
  percent: number | null; // If percent is null user types height manually as mm
  material?: MaterialID;
  direction: DoorMaterialDirection;
  materialBackSide?: MaterialID;
  directionBackSide?: DoorMaterialDirection;
};
