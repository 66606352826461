export const loadEngine = (
  url: string,
  callback: (loaded: boolean) => void,
) => {
  const existingScript = document.getElementById('engineScript');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = 'engineScript';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback(true);
    };
  }

  if (existingScript && callback) callback(false);
};
