import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { MessageActionType, MessageLevel } from '../../reducers/Message';
import { ProjectResponse, ProjectStatus } from '../../models/Project';
import { deleteProject } from '../../services/Projects';

interface ConfirmProps {
  confirmState: boolean;
  closeConfirm: () => void;
  project: ProjectResponse | null;
  finalizeDelete: (id: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      color: 'red',
    },
  }),
);

const DeleteProjectConfirm = (props: ConfirmProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = async () => {
    if (props.project) {
      const deleted = await deleteProject(props.project?.id);

      if (deleted) {
        props.finalizeDelete(props.project.id);
        props.closeConfirm();
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message: 'Projekti poistettu!',
          },
        });
      } else {
        props.closeConfirm();
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message: 'Projektia ei voitu poistaa!',
            level: MessageLevel.Error,
          },
        });
      }
    }
  };

  return (
    <div>
      <Dialog
        open={props.confirmState}
        onClose={props.closeConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Poista{' '}
          {props.project?.status === ProjectStatus.TEMPLATE
            ? 'mallipohja'
            : 'projekti'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Haluatko varmasti poistaa ${
              props.project?.status === ProjectStatus.TEMPLATE
                ? 'mallipohjan'
                : 'projektin'
            }  ${props.project?.projectNumber ?? ''}: ${
              props.project?.name ?? '-'
            }?`}
            <br />
            Tätä toimintoa ei voi peruuttaa.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeConfirm} color="primary">
            Peruuta
          </Button>
          <Button
            className={classes.deleteButton}
            onClick={handleClose}
            autoFocus
          >
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteProjectConfirm;
