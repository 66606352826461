import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useState,
} from 'react';
import { DataModel } from '../models/DataModel';
import { EngineError, EngineInterface } from '../models/Engine';
import { loadEngine } from './loadScript';

export type EngineLoadingState = {
  loaded: boolean;
  scriptLoading: boolean;
  scriptLoaded: boolean;
  engineLoading: boolean;
  engineLoaded: boolean;
  capturing: boolean;
};

enum EngineLoadingActionTypes {
  SCRIPT_LOADING = 'scriptLoading',
  SCRIPT_LOADED = 'scriptLoaded',
  ENGINE_LOADING = 'engineLoading',
  ENGINE_LOADED = 'engineLoaded',
  CAPTURING = 'capturing',
  CAPTURED = 'captured',
}

type EngineLoadingAction = { type: EngineLoadingActionTypes };

export declare class DesignEngine extends EngineInterface {}

const REACT_APP_ENGINE_BASE_URL =
  process.env.REACT_APP_ENGINE_URL || `/${process.env.PUBLIC_URL}engine/`;

const REACT_APP_ENGINE_SCRIPT_FILE =
  process.env.REACT_APP_ENGINE_SCRIPT || 'engine-lib.min.js';
export function useEngine() {
  const [
    { loaded, scriptLoaded, engineLoading, engineLoaded },
    dispatchLoadingState,
  ] = useReducer(
    (state: EngineLoadingState, action: EngineLoadingAction) => {
      switch (action.type) {
        case EngineLoadingActionTypes.SCRIPT_LOADING:
          return {
            ...state,
            scriptLoading: true,
            scriptLoaded: false,
            loaded: false,
          };
        case EngineLoadingActionTypes.SCRIPT_LOADED:
          return {
            ...state,
            scriptLoading: false,
            scriptLoaded: true,
            loaded: false,
          };
        case EngineLoadingActionTypes.ENGINE_LOADING:
          return {
            ...state,
            engingLoading: true,
            engineLoaded: false,
            loaded: false,
          };
        case EngineLoadingActionTypes.ENGINE_LOADED:
          return {
            ...state,
            engingLoading: false,
            engineLoaded: true,
            loaded: true,
          };
        case EngineLoadingActionTypes.CAPTURING:
          return {
            ...state,
            capturing: true,
          };
        case EngineLoadingActionTypes.CAPTURED:
          return {
            ...state,
            capturing: false,
          };
      }
    },
    {
      loaded: false,
      scriptLoading: false,
      scriptLoaded: false,
      engineLoading: false,
      engineLoaded: false,
      capturing: false,
    },
    undefined,
  );

  const [engine, setEngine] = useState<DesignEngine>();
  const [engineErrors, setEngineErrors] = useState<EngineError[]>([]);
  const [engineDataModel, setEngineDataModel] = useState<DataModel>();
  const [element, setEngineElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatchLoadingState({ type: EngineLoadingActionTypes.SCRIPT_LOADING });
    console.info('Loading engine script');
    loadEngine(
      REACT_APP_ENGINE_BASE_URL + REACT_APP_ENGINE_SCRIPT_FILE,
      (loaded: boolean) => {
        console.info(
          loaded ? 'Engine script loaded' : 'Engine script already loaded',
        );
        dispatchLoadingState({ type: EngineLoadingActionTypes.SCRIPT_LOADED });
      },
    );
    //no need to unload once engine script is loaded
  }, []);

  useLayoutEffect(() => {
    if (scriptLoaded && !engineLoading && !engineLoaded && element && !engine) {
      dispatchLoadingState({ type: EngineLoadingActionTypes.ENGINE_LOADING });

      const initEngine = async () => {
        const dataSetPath = `${REACT_APP_ENGINE_BASE_URL}dataset.json`;
        const dataSetResponse = await fetch(dataSetPath);
        const dataSet = await dataSetResponse.json();
        if (element) {
          const engine = new DesignEngine({
            element,
            dataSet,
            textureBaseUrl: `${REACT_APP_ENGINE_BASE_URL}textures/`,
            fontBaseUrl: `${REACT_APP_ENGINE_BASE_URL}fonts/`,
          });

          engine.setErrorListener((errors) => setEngineErrors(errors));
          setEngine(engine);

          dispatchLoadingState({
            type: EngineLoadingActionTypes.ENGINE_LOADED,
          });

          console.info('Engine initialized');
        }
      };
      initEngine();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engine, scriptLoaded, engineLoading, engineLoaded, element]);

  useEffect(() => {
    //dispose engine on component unmount
    return () => {
      if (engine) {
        console.info('Disposing engine');
        engine.dispose();
        setEngine(undefined);
      }
    };
  }, [engine, setEngine]);

  useEffect(() => {
    if (engine && engineDataModel) {
      console.info('Set datamodel for engine');
      engine.setDataModel(engineDataModel);
    }
  }, [engine, engineDataModel]);

  const refreshEngine = useCallback(() => {
    if (engine) {
      engine.refresh();
    }
  }, [engine]);

  return {
    setEngineElement,
    loaded,
    engine,
    engineErrors,
    refreshEngine,
    engineDataModel,
    setEngineDataModel,
  };
}
