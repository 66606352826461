import API from '../config/axios';
import { ChangePassword } from '../models/ChangePassword';
import { Login, LoginResponse } from '../models/Login';
import { Register } from '../models/Register';

export async function login(loginParams: Login) {
  const response = await API.post('/auth/login', loginParams);
  const loginRes = response.data as LoginResponse;
  return loginRes;
}

export async function getProfile() {
  const response = await API.get('/auth/profile');
  const loginRes = response.data as LoginResponse;
  return loginRes;
}

export type RegisterResponse = {
  error?: string;
  errors?: { field: string; message: string }[];
  status?: number;
};

export type UpdateApiKeyResponse = {
  status?: number;
  apikey?: string;
  error?: string;
};

export async function register(user: Register): Promise<RegisterResponse> {
  try {
    const response = await API.post('/auth/register', user, {});
    if (response.status === 201) {
      return { status: response.status };
    }
    return { errors: response.data.errors, status: response.status };
  } catch (err) {
    const response = err.response;
    if (response && response.data?.errors) {
      return { errors: response.data.errors, status: response.status };
    }
    return { error: `Virhe käyttäjää luodessa: ${err.message}` };
  }
}

export async function changePassword(
  userId: number,
  changePasswordRequest: Partial<ChangePassword>,
): Promise<RegisterResponse> {
  try {
    const request = { id: userId, ...changePasswordRequest };
    const response = await API.post('/auth/change-password', request);
    if (response.status === 201) {
      return { status: response.status };
    }
    return { errors: response.data.errors, status: response.status };
  } catch (err) {
    const response = err.response;
    if (response && response.data?.message) {
      return {
        errors: Array.isArray(response.data.message)
          ? response.data.message.map((message: string) => ({
              field: message.split(' ')[0],
              message,
            }))
          : [
              {
                field: 'password',
                message: response.data.message,
              },
            ],
        status: response.status,
      };
    }
    return { error: `Virhe käyttäjän salasanaa vaihdettaessa: ${err.message}` };
  }
}

export async function updateApiKey(
  userId: number,
): Promise<UpdateApiKeyResponse> {
  try {
    const response = await API.post('/auth/update-apikey', { id: userId });
    if (response.status === 200 && response.data?.apikey) {
      return { status: response.status, apikey: response.data?.apikey };
    }
    return {
      error: `Virhe käyttäjän API-avainta vaihdettaessa`,
      status: response.status,
    };
  } catch (err: any) {
    return {
      error: `Virhe käyttäjän API-avainta vaihdettaessa: ${err.message}`,
    };
  }
}

export async function logout() {
  await API.delete('/auth/logout');
}
