import {
  Box,
  Button,
  Container,
  makeStyles,
  Popover,
  TextField,
  Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import './LoginPage.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Login } from '../../models/Login';
import { login } from '../../actions/Auth';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { LoginState } from '../../reducers/Auth';

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#8F1F65',
    height: '3rem',
    borderRadius: 0,
  },
  link: {
    textAlign: 'center',
    color: '#8F1F65',
  },
  logo: {
    width: 250,
    height: 40,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${
      process.env.PUBLIC_URL + '/img/lakeus-logo.png'
    }")`,
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginState = useSelector((state: AppState) => state.auth.loginState);
  const { state } = useLocation();

  let loginSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (formData: Login) => {
    dispatch(login(formData.username, formData.password));
  };

  const [popUpAnchorEl, setPopUpAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleForgotPasswordClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setPopUpAnchorEl(event.currentTarget);
  };

  const handleForgotPasswordClose = () => {
    setPopUpAnchorEl(null);
  };

  if (loginState === LoginState.LoggedIn) {
    return <Navigate state={state as any} to={(state as any)?.from || '/'} />;
  }

  const popUpOpen = Boolean(popUpAnchorEl);

  return (
    <>
      <Container maxWidth="xs">
        <div className="Login__Container">
          <div className="Login__Container__Header">
            <div
              className={`Login__Container__Header__Title ${classes.logo}`}
            ></div>
            <div className="Login__Container__Header__Subtitle">
              Kirjaudu sisään
            </div>
          </div>
          <div className="Login__Container__Content">
            <form
              className="Login__Container__Form"
              onSubmit={handleSubmit((d) => onSubmit(d as Login))}
              noValidate
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label="Käyttäjänimi"
                autoFocus
                {...register('username', { required: true })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Salasana"
                type="password"
                id="password"
                autoComplete="current-password"
                {...register('password', { required: true })}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Kirjaudu sisään
              </Button>
            </form>
            <Button
              className={classes.link}
              onClick={handleForgotPasswordClick}
            >
              Unohditko salasanasi?
            </Button>
            <Popover
              id={popUpOpen ? 'simple-popover' : undefined}
              open={popUpOpen}
              anchorEl={popUpAnchorEl}
              onClose={handleForgotPasswordClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box m={2}>
                Ota yhtyettä Lakeus Design:iin sähköpostitse tai puhelimitse
                salasanan vaihtoa varten.
              </Box>
            </Popover>
          </div>
        </div>
      </Container>
    </>
  );
};

export default LoginPage;
