import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import React, { useState } from 'react';
import { SideBarSector } from '../../containers/sideBarSector/SideBarSector';
import { SideBarSectorItem } from '../../containers/sideBarSectorItem/SideBarSectorItem';
import {
  ExtraView,
  ProjectResponse,
  ProjectStatus,
} from '../../models/Project';
import { UserRole } from '../../models/User';

export const ProjectInfoPanel = (props: {
  project?: ProjectResponse;
  onChange: () => void;
  onExport: (factory: boolean) => void;
  canEdit: boolean;
  canSave: boolean;
  hasErrors: boolean;
  role: UserRole;
}) => {
  const { project, onChange, canEdit, role } = props;
  const [, updateDataState] = useState({});

  if (!project) {
    return null;
  }

  const addExtra = () => {
    if (!project.extras) {
      project.extras = [];
    }
    project.extras.push({
      price: 0,
      productId: '',
      views: [],
    });
    updateDataState({});
    onChange();
  };

  const removeExtra = (index: number) => {
    if (project.extras && project.extras.length > index) {
      project.extras.splice(index, 1);
    }
    updateDataState({});
    onChange();
  };

  function onDataChange<I, O>(
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
    value?: I,
    transformer?: (value: I) => O,
  ) {
    if (!event.target.name) return;
    const path = event.target.name.split('.');
    let dst = project as any;
    if (path.length > 1) {
      for (const segment of path.slice(0, path.length - 1)) {
        if (!dst[segment]) {
          return;
        }
        dst = dst[segment];
      }
    }
    dst[path[path.length - 1]] = transformer
      ? transformer(value ?? (event.target.value as I))
      : (value ?? event.target.value);
    updateDataState({});
    onChange();
  }

  return (
    <>
      <SideBarSector defaultExpanded title={`Tarjous ${project.projectNumber}`}>
        <SideBarSectorItem label="Viite" name="name">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="name"
              name="name"
              value={project.name || ''}
              onChange={onDataChange}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem
          label="Tila"
          name="status"
          error={
            props.hasErrors
              ? 'Korjaa suunnitelman virheet ennen tilan muutosta'
              : undefined
          }
        >
          <FormControl component="fieldset">
            {getStateOptions(role, project, props.hasErrors, onDataChange)}
          </FormControl>
        </SideBarSectorItem>
        {project.status === ProjectStatus.INPRODUCTION && [
          <SideBarSectorItem label="Toimitusviikko" name="deliveryWeek">
            <FormControl component="fieldset">
              <TextField
                variant="outlined"
                aria-label="deliveryWeek"
                name="deliveryWeek"
                value={project.deliveryWeek || ''}
                onChange={onDataChange}
              />
            </FormControl>
          </SideBarSectorItem>,
          <SideBarSectorItem label="Toimitus" name="delivery">
            <FormControl variant="outlined">
              <Select
                name="deliveryDirection"
                value={project.deliveryDirection ?? ''}
                onChange={(event) => onDataChange<string, string>(event)}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="etela">Etelä</MenuItem>
                <MenuItem value="pohjoinen">Pohjoinen</MenuItem>
                <MenuItem value="keski">Keski</MenuItem>
                <MenuItem value="lahi">Lähi</MenuItem>
                <MenuItem value="nouto">Nouto</MenuItem>
              </Select>
            </FormControl>
          </SideBarSectorItem>,
          <SideBarSectorItem label="Toimitustapa" name="delivery">
            <FormControl variant="outlined">
              <Select
                name="deliveryTarget"
                value={project.deliveryTarget ?? ''}
                onChange={(event) => onDataChange<string, string>(event)}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="ap">AP</MenuItem>
                <MenuItem value="liike">LIIKE</MenuItem>
              </Select>
            </FormControl>
          </SideBarSectorItem>,
        ]}
        <SideBarSectorItem
          label="Lataa"
          error={
            props.hasErrors
              ? 'Korjaa suunnitelman virheet ennen PDF:n lataamista'
              : undefined
          }
        >
          <FormControl component="fieldset">
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.onExport(false)}
              disabled={props.hasErrors || props.canSave}
              title="Lataa kuluttajan PDF"
            >
              Lataa kuluttajan PDF
            </Button>
          </FormControl>
          {role !== UserRole.RETAILER && (
            <FormControl component="fieldset">
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.onExport(true)}
                disabled={props.hasErrors || props.canSave}
                title="Lataa tehtaan PDF"
              >
                Lataa tehtaan PDF
              </Button>
            </FormControl>
          )}
        </SideBarSectorItem>
      </SideBarSector>
      <SideBarSector defaultExpanded title="Asiakas">
        <SideBarSectorItem label="Etunimi*" name="customer.firstName">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.firstName"
              name="customer.firstName"
              value={project.customer?.firstName || ''}
              onChange={onDataChange}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Sukunimi*" name="customer.lastName">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.lastName"
              name="customer.lastName"
              value={project.customer?.lastName || ''}
              onChange={onDataChange}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Asiakastunnus*" name="customer.customerID">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.customerID"
              name="customer.customerID"
              value={project.customer?.customerID || ''}
              onChange={onDataChange}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Osoite*" name="customer.address">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.address"
              name="customer.address"
              value={project.customer?.address || ''}
              onChange={onDataChange}
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Postinumero*" name="customer.postalCode">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.postalCode"
              name="customer.postalCode"
              value={project.customer?.postalCode || ''}
              onChange={onDataChange}
              type="number"
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Postitoimipaikka*" name="customer.city">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.city"
              name="customer.city"
              value={project.customer?.city || ''}
              onChange={onDataChange}
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Sähköpostiosoite" name="customer.eMail">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.eMail"
              name="customer.eMail"
              value={project.customer?.eMail || ''}
              onChange={onDataChange}
              type="email"
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Puhelinnumero" name="customer.phoneNumber">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.phoneNumber"
              name="customer.phoneNumber"
              value={project.customer?.phoneNumber || ''}
              onChange={onDataChange}
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem
          label="Toimituksen lisätiedot"
          name="customer.additionalDetails"
        >
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="customer.additionalDetails"
              name="customer.additionalDetails"
              value={project.customer?.additionalDetails || ''}
              onChange={onDataChange}
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>
      </SideBarSector>
      <SideBarSector defaultExpanded title="Lisätiedot">
        <SideBarSectorItem label="Tilauksen lisätiedot" name="notes">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="notes"
              name="notes"
              value={project.notes || ''}
              onChange={onDataChange}
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>

        <SideBarSectorItem label="Tehtaan kommentit" name="factoryNotes">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              disabled={!canEdit}
              aria-label="factoryNotes"
              name="factoryNotes"
              value={project.factoryNotes || ''}
              onChange={onDataChange}
              multiline={true}
            />
          </FormControl>
        </SideBarSectorItem>
      </SideBarSector>
      <SideBarSector defaultExpanded title="Lisätyöt">
        {project.extras &&
          project.extras.map((extra, index) => (
            <SideBarSectorItem
              key={index}
              label={`Lisätyö (${index + 1})`}
              disabled={!canEdit}
              onRemove={() => removeExtra(index)}
            >
              <FormControl component="fieldset">
                <TextField
                  variant="outlined"
                  disabled={!canEdit}
                  aria-label="notes"
                  name={`extras.${index}.productId`}
                  value={extra.productId || ''}
                  onChange={onDataChange}
                  label="Kuvaus"
                  multiline={true}
                />
                <TextField
                  variant="outlined"
                  disabled={!canEdit}
                  aria-label="factoryNotes"
                  name={`extras.${index}.price`}
                  value={extra.price || 0}
                  onChange={(event) =>
                    onDataChange(event, event.target.value, parseFloat)
                  }
                  type="number"
                  label="Hinta €"
                />
                {role !== UserRole.RETAILER &&
                  project.extras &&
                  project.extras[index] && (
                    <FormControlLabel
                      key={'cb1'}
                      control={
                        <Checkbox
                          checked={project.extras[index]?.views?.includes(
                            ExtraView.SAW,
                          )}
                          disabled={!canEdit}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            if (project.extras) {
                              if (checked) {
                                onDataChange(event, [
                                  ...(project.extras[index]?.views ?? []),
                                  ExtraView.SAW,
                                ]);
                              } else {
                                onDataChange(
                                  event,
                                  project.extras[index]?.views?.filter(
                                    (v) => v !== ExtraView.SAW,
                                  ) ?? [],
                                );
                              }
                            }
                          }}
                          name={`extras.${index}.views`}
                          color="primary"
                        />
                      }
                      label="Näytä sahalapussa"
                    />
                  )}
                {role !== UserRole.RETAILER &&
                  project.extras &&
                  project.extras[index] && (
                    <FormControlLabel
                      key={'cb2'}
                      control={
                        <Checkbox
                          checked={project.extras[index]?.views?.includes(
                            ExtraView.COLLECT,
                          )}
                          disabled={!canEdit}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            if (project.extras) {
                              if (checked) {
                                onDataChange<string[], string[]>(event, [
                                  ...(project.extras[index]?.views ?? []),
                                  ExtraView.COLLECT,
                                ]);
                              } else {
                                onDataChange<string[], string[]>(
                                  event,
                                  project.extras[index]?.views?.filter(
                                    (v) => v !== ExtraView.COLLECT,
                                  ) ?? [],
                                );
                              }
                            }
                          }}
                          name={`extras.${index}.views`}
                          color="primary"
                        />
                      }
                      label="Näytä keräyslapussa"
                    />
                  )}
              </FormControl>
            </SideBarSectorItem>
          ))}
        <SideBarSectorItem>
          <Button
            disabled={!canEdit}
            className="wideItem"
            onClick={() => addExtra()}
            color="primary"
          >
            + Lisää lisätyö
          </Button>
        </SideBarSectorItem>
      </SideBarSector>
    </>
  );
};

function getStateOptions(
  role: UserRole,
  project: ProjectResponse,
  hasErrors: boolean,
  onDataChange: <I, O>(
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>,
    value?: I,
    transformer?: ((value: I) => O) | undefined,
  ) => void,
) {
  if (
    project.status === ProjectStatus.INPRODUCTION &&
    role === UserRole.RETAILER
  ) {
    return <Typography>Viety tuotantoon</Typography>;
  }
  return (
    <RadioGroup
      aria-label="status"
      name="status"
      value={project.status}
      onChange={onDataChange}
    >
      <FormControlLabel
        value={ProjectStatus.INPROGRESS}
        control={<Radio color="primary" />}
        label="Tarjous"
      />
      <FormControlLabel
        value={ProjectStatus.ORDERSENT}
        control={<Radio color="primary" disabled={hasErrors} />}
        label="Tilaus"
      />
      {role !== UserRole.RETAILER && (
        <FormControlLabel
          value={ProjectStatus.INPRODUCTION}
          control={<Radio color="primary" disabled={hasErrors} />}
          label="Tuotannossa"
        />
      )}
    </RadioGroup>
  );
}
