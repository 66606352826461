import React from 'react';
import {
  createStyles,
  makeStyles,
  Portal,
  Snackbar,
  Theme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { clearMessage } from '../../actions/Message';
import { MessageLevel } from '../../reducers/Message';
import { Alert } from '@material-ui/lab';

const MESSAGE_TIMEOUT = 2000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 80,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
    },
    [`level-${MessageLevel.Info}`]: {
      backgroundColor: 'green',
    },
    [`level-${MessageLevel.Error}`]: {
      backgroundColor: 'red',
    },
  }),
);

export const Notification = () => {
  const messageState = useSelector((state: AppState) => state.message);

  const dispatch = useDispatch();
  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearMessage(messageState.id));
  };

  const classes = useStyles();

  const getSeverity = () => {
    switch (messageState.level) {
      case MessageLevel.Info:
        return 'info';
      case MessageLevel.Error:
        return 'error';
    }
    return 'info';
  };

  return messageState.message ? (
    <div className={classes.root}>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!messageState.message}
          message={messageState.message}
          onClose={handleClose}
          autoHideDuration={
            messageState.level === MessageLevel.Info ? MESSAGE_TIMEOUT : null
          }
        >
          <Alert onClose={handleClose} severity={getSeverity()}>
            {messageState.message}
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  ) : null;
};
