import { Dispatch } from 'redux';
import { LoginResponse } from '../models/Login';
import { AuthActionType } from '../reducers/Auth';
import { MessageActionType, MessageLevel } from '../reducers/Message';
import * as AuthService from '../services/Auth';

export const login =
  (username: string, password: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: AuthActionType.LoggingIn,
    });

    return AuthService.login({ username: username, password: password }).then(
      (data: LoginResponse) => {
        dispatch({
          type: AuthActionType.Login,
          payload: { user: { ...data.user, accessToken: data.accessToken } },
        });

        dispatch({
          type: MessageActionType.SetMessage,
          payload: { message: 'Sinut on kirjattu sisään!' },
        });
      },
      (error) => {
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message:
              'Sisäänkirjautuminen epäonnistui, tarkista käyttäjätunnus ja salasana!',
            level: MessageLevel.Error,
          },
        });
        dispatch({
          type: AuthActionType.Logout,
        });
      },
    );
  };

export const getProfile = (showError: boolean) => (dispatch: Dispatch) => {
  return AuthService.getProfile().then(
    (data: LoginResponse) => {
      dispatch({
        type: AuthActionType.Login,
        payload: { user: data.user },
      });
    },
    (error) => {
      if (showError) {
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message: 'Kirjaudu sisään jatkaaksesi',
            level: MessageLevel.Error,
          },
        });
      }
      dispatch({
        type: AuthActionType.Logout,
      });
    },
  );
};

export const logout = () => (dispatch: Dispatch) => {
  dispatch({
    type: AuthActionType.LoggingOut,
  });

  try {
    AuthService.logout();
  } catch (error) {
    dispatch({
      type: MessageActionType.SetMessage,
      payload: {
        message: 'Virhe uloskirjautuessa! Kokeile kirjautua ulos uudelleen!',
        level: MessageLevel.Error,
      },
    });
  }

  dispatch({
    type: AuthActionType.Logout,
  });
};
