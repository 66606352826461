import {
  Backdrop,
  Button,
  Container,
  Fade,
  Modal,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { FormEvent, useEffect, useState } from 'react';
import './LogoModal.scss';
import * as LogoService from '../../services/Logos';
import { useDispatch } from 'react-redux';
import { MessageActionType, MessageLevel } from '../../reducers/Message';
import { setMessage } from '../../actions/Message';
import { Autocomplete } from '@material-ui/lab';
import { User } from '../../models/User';

interface ModalProps {
  modalState: boolean;
  users: User[];
  closeModal: () => void;
}

const useStyles = makeStyles({
  errorMsg: {
    color: 'red',
    fontSize: '0.75rem',
  },
  buttonsContainer: {
    marginTop: '1rem',
  },
  cancel: {
    flexGrow: 1,
    marginRight: '',
  },
  submit: {
    float: 'right',
  },
  logoFileName: {
    display: 'inline-block',
    paddingLeft: '5px',
  },
});

const LogoModal = (props: ModalProps) => {
  const [file, setFile] = useState<null | File>(null);
  const [company, setCompany] = useState<string | null>(null);
  const [companies, setCompanies] = useState<string[]>([]);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    if (props.modalState) {
      setCompanies([
        ...new Set<string>(props.users.map((user) => user.company)),
      ]);
    }
  }, [props.modalState, props.users]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file) {
      dispatch(setMessage('Tiedosto puuttuu', MessageLevel.Error));
      return;
    }
    if (!company) {
      dispatch(setMessage('Yritystä ei ole valittu', MessageLevel.Error));
      return;
    }
    console.log(`Sending logo "${file.name}" for company "${company}"`);
    const success = await LogoService.uploadCompanyLogo(company, file);

    if (!success) {
      dispatch(setMessage('Logon lähetys epäonnistui', MessageLevel.Error));
    } else {
      dispatch({
        type: MessageActionType.SetMessage,
        payload: {
          message: 'Logo päivitetty',
        },
      });

      props.closeModal();
      return;
    }
    return;
  };

  const body = (
    <Fade in={props.modalState} timeout={500}>
      <div className="Modal__Content">
        <form onSubmit={onSubmit} noValidate>
          <h2>Päivitä yrityksen logo</h2>
          <Autocomplete
            id="company"
            options={companies}
            autoComplete={true}
            autoSelect={true}
            renderInput={(params) => (
              <TextField {...params} name="company" variant="outlined" />
            )}
            onChange={(event: React.ChangeEvent<{}>, value: string | null) => {
              setCompany(value || null);
            }}
          />
          <Button variant="contained" component="label" color="secondary">
            Valitse kuva
            <input
              accept="image/*"
              type="file"
              hidden
              onChange={(e) => handleFileChange(e.target.files)}
            />
          </Button>
          {file && <div className={classes.logoFileName}>{file.name}</div>}
          <Container disableGutters={true} className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.closeModal}
              className={classes.cancel}
            >
              Peruuta
            </Button>
            <Button
              disabled={!file}
              type="submit"
              value="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Lähetä logo
            </Button>
          </Container>
        </form>
      </div>
    </Fade>
  );

  return (
    <Modal
      className="Modal"
      open={props.modalState}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
    >
      {body}
    </Modal>
  );
};

export default LogoModal;
