import React, {
  Children,
  isValidElement,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1rem',
    },
    heading: {
      textTransform: 'uppercase',
      color: 'rgba(143,31,101,1)',
    },
    column: {
      flexBasis: '50%',
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    error: {
      borderRight: '6px solid red',
      paddingRight: '10px',
    },
    fatDivider: {
      height: '5px',
    },
  }),
);

export const SideBarSector = (props: {
  children: ReactNode;
  title: string;
  defaultExpanded?: boolean;
  onExpansionChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
}): ReactElement => {
  const classes = useStyles();
  const hasErrors = useMemo<boolean>(() => {
    return Children.toArray(props.children).some(
      (child) => isValidElement(child) && !!child.props?.error,
    );
  }, [props.children]);

  const wrapChild = (child: ReactNode): ReactElement | null => {
    if (!isValidElement(child)) return null;
    return (
      <>
        <Divider
          className={child.props?.fatDivider ? classes.fatDivider : undefined}
        />
        <AccordionDetails
          className={child.props?.error ? classes.error : undefined}
        >
          {child}
        </AccordionDetails>
      </>
    );
  };

  return (
    <Accordion
      defaultExpanded={props.defaultExpanded ?? false}
      onChange={props.onExpansionChange}
      className={classes.root}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={hasErrors ? classes.error : undefined}
      >
        <Typography className={classes.heading}>{props.title}</Typography>
      </AccordionSummary>
      {Children.map(props.children, (child) =>
        wrapChild(child as ReactElement),
      )}
    </Accordion>
  );
};
