import './App.scss';
import React, { useEffect } from 'react';
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { Home } from '../home/Home';
import LoginPage from '../loginPage/LoginPage';
import { TopBar } from '../../components/topBar/TopBar';
import { Notification } from '../../components/notification/Notification';
import { Background } from '../../components/background/Background';
import UsersPage from '../usersPage/UsersPage';
import ProjectPage from '../projectPage/ProjectPage';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { AppState } from '../../reducers';
import { LoginState } from '../../reducers/Auth';
import { LinearProgress } from '@material-ui/core';
import { getProfile } from '../../actions/Auth';

const RequireAuth = () => {
  const authState = useSelector((state: AppState) => state.auth, shallowEqual);
  const location = useLocation();

  return authState.loginState === LoginState.LoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

const App = () => {
  const loginState = useSelector((state: AppState) => state.auth.loginState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loginState === LoginState.CheckLogin) {
      const loginPage = window.location.href.includes('/login');
      dispatch(getProfile(!loginPage));
    }
  }, [loginState, dispatch]);

  let content;
  if (loginState === LoginState.CheckLogin) {
    content = <LinearProgress />;
  } else {
    content = (
      <Routes>
        <Route caseSensitive path="/login" element={<LoginPage />}></Route>
        <Route element={<RequireAuth />}>
          <Route path={'/projects/:id'} element={<ProjectPage />}></Route>
          <Route path={'/users'} element={<UsersPage />}></Route>
          <Route path={'/'} element={<Home />}></Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  return (
    <BrowserRouter>
      <Background />
      <TopBar />
      {content}
      <Notification />
    </BrowserRouter>
  );
};

export default App;
