import {
  Backdrop,
  Button,
  Container,
  Fade,
  Modal,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import './changePasswordModal.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as AuthService from '../../services/Auth';
import { useDispatch } from 'react-redux';
import { MessageActionType, MessageLevel } from '../../reducers/Message';
import { setMessage } from '../../actions/Message';
import { ChangePassword } from '../../models/ChangePassword';
import { User } from '../../models/User';

interface ModalProps {
  modalState: boolean;
  closeModal: () => void;
  user: User;
}

const changePasswordSchema = yup.object().shape({
  password: yup.string().required(),
  password_confirmation: yup.string().required(),
});

const useStyles = makeStyles({
  errorMsg: {
    color: 'red',
    fontSize: '0.75rem',
  },
  buttonsContainer: {
    marginTop: '1rem',
  },
  cancel: {
    flexGrow: 1,
    marginRight: '',
  },
  submit: {
    float: 'right',
  },
});

const ChangePasswordModal = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = async (formData: Partial<ChangePassword>) => {
    const { error, errors } = await AuthService.changePassword(
      props.user.id,
      formData,
    );
    if (error) {
      dispatch(setMessage(error, MessageLevel.Error));
    } else if (errors && errors.length) {
      for (const error of errors) {
        setError(error.field, {
          type: 'manual',
          message: error.message,
        });
      }
    } else {
      dispatch({
        type: MessageActionType.SetMessage,
        payload: {
          message: 'Salasana vaihdettu',
        },
      });

      props.closeModal();
      return;
    }

    return;
  };

  const body = (
    <Fade in={props.modalState} timeout={500}>
      <div className="Modal__Content">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <h2>Vaihda käyttäjän salasana</h2>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Salasana"
            type="password"
            id="password"
            error={errors.password ? true : false}
            {...register('password', { required: true })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Salasanan vahvistus"
            type="password"
            id="password_confirmation"
            error={errors.password_confirmation ? true : false}
            {...register('password_confirmation', { required: true })}
          />
          {errors.password && (
            <span className={classes.errorMsg}>
              {errors.password && errors.password.message}
            </span>
          )}
          <Container disableGutters={true} className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.closeModal}
              className={classes.cancel}
            >
              Peruuta
            </Button>
            <Button
              type="submit"
              value="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Vaihda salasana
            </Button>
          </Container>
        </form>
      </div>
    </Fade>
  );

  return (
    <Modal
      className="Modal"
      open={props.modalState}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
    >
      {body}
    </Modal>
  );
};

export default ChangePasswordModal;
