import { OrderProduct } from '.';

export enum OrderBaseGroup {
  FRAME = 'frame',
  DOORS = 'doors',
  EXTRAS = 'extras',
}

export type OrderGroup = {
  name: string;
  weight: number;
  subGroups: OrderGroup[];
  products: OrderProduct[];
  filter?: string[];
};

export enum OrderGroups {
  FRAME_PLATES = 'Levyt',
  INNER_PLATES = 'Välilevyt',
  PLINTH = 'Sokkeli',
  BACKGROUND_PLATE = 'Taustalevyt',
  PARTS = 'Hilut',
  DRAWERS = 'Laatikot',
  COMPONENTS = 'Komponentit',
  EXTRAS = 'Lisätyöt',
  DOORS = 'Ovet',
}

export enum OrderGroupsWeights {
  FRAME_PLATES = 100,
  PLINTH = 200,
  INNER_PLATES = 300,
  DRAWERS = 400,
  BACKGROUND_PLATE = 500,
  COMPONENTS = 600,
  PARTS = 700,
  DOORS = 1000,
  DOOR_EXTRAS = 1100,
  EXTRAS = 10000,
}

export enum BaseWeights {
  FRAME_START = 0,
  FRAME_END = 100,
  COVER_PLATE = 200,
  BASE_PLATE = 300,
  PLINTH = 400,
  INNER_WALL = 500,
  SHELF = 600,
  DRAWER = 700,
  BACKGROUND_PLATE = 800,
  COMPONENTS = 1000,
  EXTRAS = 2000,
}
